<script lang="ts" setup>
import { useI18n } from 'nuxt-i18n-composable'

import BaseDialog from '@/components/dialogs/BaseDialog.vue'

defineProps<{
  loading: boolean
}>()

const emit = defineEmits<{
  (event: 'close'): void
  (event: 'action'): void
}>()

const { t } = useI18n()
</script>

<template>
  <base-dialog
    data-cy="delete_signature_dialog"
    max-width="510"
    action-color="error"
    :title="t('dialog.delete_signature.title')"
    :action-text="t('dialog.delete_signature.action_text')"
    :loading="loading"
    @close="emit('close')"
    @action="emit('action')"
  >
    <div>
      {{ t('dialog.delete_signature.information') }}
    </div>
  </base-dialog>
</template>
